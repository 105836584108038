import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { produce } from 'immer'
import { CampaignEmissionScheduleModel } from '../models/campaign-emission-schedule.model'

const defaultState: CampaignEmissionScheduleModel = {
  readOnly: false,
  campaignData: undefined,
  schedule: {
    show: true,
    loading: false,
    loaded: false,
    creationSchedules: [],
  },
  locations: {
    show: false,
    loading: false,
  },
  emissionNumber: {
    show: true,
    loading: false,
    emissionsNumber: '',
    audienceNumber: '',
  },
  map: {
    show: true,
  },
}

const initialState: CampaignEmissionScheduleModel = produce(defaultState, draft => draft)

const campaignEmissionScheduleSlice = createSlice({
  name: 'campaignEmissionSchedule',
  initialState,
  reducers: {
    setReadOnly(state, action: PayloadAction<boolean>) {
      state.readOnly = action.payload
    },
    setCampaignData(state, action: PayloadAction<CampaignEmissionScheduleModel['campaignData']>) {
      state.campaignData = action.payload
    },
    setCreationSchedules(
      state,
      action: PayloadAction<CampaignEmissionScheduleModel['schedule']['creationSchedules']>
    ) {
      state.schedule.creationSchedules = action.payload
    },
    setCreationSchedulesLoaded(state, action: PayloadAction<boolean>) {
      state.schedule.loaded = action.payload
    },
    setEmissionsNumber(
      state,
      action: PayloadAction<{ key: 'emissionsNumber' | 'audienceNumber'; value: string }>
    ) {
      state.emissionNumber[action.payload.key] = action.payload.value
    },
    setLoading(
      state,
      action: PayloadAction<{ key: 'schedule' | 'locations' | 'emissionNumber'; value: boolean }>
    ) {
      state[action.payload.key].loading = action.payload.value
    },
    setEmissionsNumberLoading(state, action: PayloadAction<boolean>) {
      state.emissionNumber.loading = action.payload
    },
    setLocationsLoading(state, action: PayloadAction<boolean>) {
      state.locations.loading = action.payload
    },
    toggleShow(
      state,
      { payload }: PayloadAction<'schedule' | 'locations' | 'emissionNumber' | 'map'>
    ) {
      state[payload].show = !state[payload].show
    },
    resetState() {
      return initialState
    },
  },
})

export const campaignEmissionScheduleActions = campaignEmissionScheduleSlice.actions

export default campaignEmissionScheduleSlice
