import { InventoryTarget } from '../../models/inventory-common-slice.model'

export class InventoryTranslationsKeysHelper {
  static getName(slotDuration: number, target: InventoryTarget, lowerCase?: boolean): string {
    let result = slotDuration == 0 ? 'Seconds' : 'Slots'

    if (target === 'audience') {
      result = 'Audience'
    }

    if (lowerCase) {
      return result.toLowerCase()
    }

    return result
  }
}
