import { client } from 'components/ApolloProvider'
import { GET_INVENTORY_FOR_PROGRESS_BAR } from './getInventoryForProgressBar'
import { GET_INVENTORY_FOR_CHART } from './getInventoryForChart'
import {
  GetInventoryFiltersQueryData,
  GetInventoryFiltersVariables,
  GetInventoryForAlertsAQR,
  GetInventoryForChartAQR,
  GetInventoryForProgressBarAQR,
} from './types'
import { InventoryChartFilters } from 'pages/Inventory/models/types'
import { GET_INVENTORY_ALERTS } from './getInventoryAlerts'
import { GET_INVENTORY_BUILDINGS } from './getInventoryBuildings'
import { ITEMS_PER_PAGE } from '../../constant'
import { FetchResult } from '@apollo/client'
import { InventoryBuildingModel } from '../../pages/Inventory/models/inventory-buiding.model'
import { GET_INVENTORY_FILTERS } from './getInventoryFilters'

const inventoryApi = {
  getInventoryForProgressBar: (
    variables: Partial<InventoryChartFilters>,
    inContacts: boolean
  ): Promise<GetInventoryForProgressBarAQR> =>
    client.query({
      query: GET_INVENTORY_FOR_PROGRESS_BAR,
      variables: {
        ...variables,
        inContacts,
      },
    }),

  getInventoryForChart: (
    variables: Partial<InventoryChartFilters>,
    inContacts: boolean
  ): Promise<GetInventoryForChartAQR> =>
    client.query({
      query: GET_INVENTORY_FOR_CHART,
      variables: {
        ...variables,
        inContacts,
      },
    }),

  getInventoryAlerts: (): Promise<GetInventoryForAlertsAQR> =>
    client.query({
      query: GET_INVENTORY_ALERTS,
    }),

  fetchInventoryBuildings: (
    fromDate: string,
    toDate: string,
    topTen: boolean,
    cities?: string[],
    agglomerations?: string[],
    buildings?: string[],
    first?: number
  ): Promise<
    FetchResult<{ inventoryBuildings: { nodes: InventoryBuildingModel[]; totalCount: number } }>
  > => {
    return client.query({
      query: GET_INVENTORY_BUILDINGS,
      variables: {
        ...(fromDate ? { fromDate } : { fromDate: null }),
        ...(toDate ? { toDate } : { toDate: null }),
        topTen,
        ...(cities ? { cities } : {}),
        ...(agglomerations ? { agglomerations } : {}),
        ...(buildings ? { buildings } : {}),
        ...(first ? { first } : {}),
        ...(first ? { last: ITEMS_PER_PAGE } : {}),
      },
    })
  },

  fetchInventoryFilters: (
    payload: GetInventoryFiltersVariables
  ): Promise<FetchResult<GetInventoryFiltersQueryData>> => {
    return client.query({
      query: GET_INVENTORY_FILTERS,
      variables: {
        ...payload,
      },
    })
  },
}

export default inventoryApi
