import React, { useCallback, useEffect, useState } from 'react'
import FlexRow from '../../../../../components/Layout/FlexRow'
import FlexCol from '../../../../../components/Layout/FlexCol'
import Preview from '../../../../../components/Preview'
import VariableUtils from '../../../../../utils/variable'
import './CreationsFormEmissionNumber.scss'
import Input from '../../../../../components/Form/Input'
import { InputMask } from '../../../../../utils/inputMask'
import { useTranslation } from 'react-i18next'
import service from '../../services/creations-form-emission-number.service'
import debounce from 'lodash/debounce'
import { DEBOUNCE_POST_DELAY_DEFAULT } from '../../../../../constant'
import CreationsEmissionsTooltipAlert from '../../../CampaignForm/CreationsForm/CreationEmissionsTooltipAlert'
import { CreationsFormEmissionNumberProps } from '../../models/types'
import { useDispatch, useSelector } from 'react-redux'
import { campaignEmissionScheduleActions } from '../../store/campaign-emission-schedule-slice'
import { RootState } from '../../../../../store'

const CreationsFormEmissionNumber: React.FC<CreationsFormEmissionNumberProps> = ({
  creationId,
  creations,
  campaignEmissions,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [errors, setErrors] = useState<string[]>([])
  const creation = creations.find(c => c.id === creationId)!
  const { readOnly, emissionNumber } = useSelector(
    (state: RootState) => state.campaignEmissionSchedule
  )

  const debouncedCall = useCallback(
    debounce(emissionsNumber => {
      return service.updateCreationEmissionsNumber(
        creation.id,
        parseInt(emissionsNumber),
        setErrors,
        t
      )
    }, DEBOUNCE_POST_DELAY_DEFAULT),
    []
  )

  useEffect(() => {
    setErrors([])

    if (readOnly) {
      return
    }

    debouncedCall(emissionNumber.emissionsNumber)
  }, [emissionNumber.emissionsNumber, readOnly])

  useEffect(() => {
    if (creation.impressions) {
      dispatch(
        campaignEmissionScheduleActions.setEmissionsNumber({
          key: 'emissionsNumber',
          value: creation.impressions.toString(),
        })
      )
    }
  }, [creation])

  return (
    <FlexRow className='CreationsFormEmissionNumber--row'>
      <FlexCol>
        <Preview
          className={'CreationsFormEmissionNumber--preview'}
          src={creation.file.url}
          thumbnailSrc={creation.thumbnailUrl}
          isVideo={creation.file.isVideo}
          alt={creation.file.filename}
        />
      </FlexCol>

      <FlexCol>
        <div className={'CreationsFormEmissionNumber__emissions'}>
          <p>
            {t('form.campaign.details.target')}:
            <span className={'CreationsFormEmissionNumber__emissions--number'}>
              &nbsp;{VariableUtils.formatNumber(campaignEmissions)}
            </span>
          </p>
          <div className={'CreationsFormEmissionNumber__emissions--label'}>
            <span>
              {t('form.campaign.creations.creationEmissions')}:&nbsp;
              <b>{creation.file.filename}</b>
            </span>
            <CreationsEmissionsTooltipAlert
              creations={creations}
              currentCreationId={creation.id}
              campaignEmissions={campaignEmissions}
              creationEmissionNumber={emissionNumber.emissionsNumber}
            />
          </div>
          <Input
            id='emissions'
            title={''}
            help={t('form.campaign.details.emissionsHelp')}
            dataCy={'number-of-emissions'}
            value={emissionNumber.emissionsNumber}
            onChange={(value: string) => {
              dispatch(
                campaignEmissionScheduleActions.setEmissionsNumber({
                  key: 'emissionsNumber',
                  value,
                })
              )
            }}
            maskType={InputMask.INTEGER}
            errors={errors}
            readOnly={!!readOnly}
          />
        </div>
      </FlexCol>
    </FlexRow>
  )
}

export default CreationsFormEmissionNumber
