import api from 'api'
import { PrivateViewIds, PublicViewIds } from '../models/types'
import { Routes } from 'routes'
import { NavigateFunction } from 'react-router-dom'
import { MediaFormat } from 'types/campaign'
import { store } from '../../../../store'
import { campaignEmissionScheduleActions } from '../store/campaign-emission-schedule-slice'

export default class EmissionScheduleService {
  static getCampaignData = (
    privateViewIds: PrivateViewIds | undefined,
    publicViewIds: PublicViewIds | undefined,
    navigate: NavigateFunction
  ): Promise<void> => {
    if (publicViewIds?.urlCampaignUuid) {
      return api.campaign
        .getCampaignForEmissionSchedulePublic(publicViewIds.urlCampaignUuid)
        .then(response => response.data.campaignReportPublic.campaign)
        .then(data => {
          void store.dispatch(
            campaignEmissionScheduleActions.setCampaignData({
              ...data,
              mediumFormat: MediaFormat[data.mediumFormat as string as keyof typeof MediaFormat],
            })
          )
        })
        .catch(() => void navigate(Routes.SIGN.SIGN_IN))
    }

    if (privateViewIds?.urlCampaignId) {
      return api.campaign
        .getCampaignForEmissionSchedule(privateViewIds.urlCampaignId)
        .then(response => response.data.node)
        .then(data => {
          void store.dispatch(
            campaignEmissionScheduleActions.setCampaignData({
              ...data,
              mediumFormat: MediaFormat[data.mediumFormat as string as keyof typeof MediaFormat],
            })
          )
        })
        .catch(() => void {})
    }

    return Promise.resolve()
  }
}
