import api from 'api'
import { DateRange } from 'components/Form/DatePickerRange'
import DateUtils from 'utils/date'
import { Creation, CreationSchedule } from 'types/campaign'
import VariableUtils from 'utils/variable'
import { DATE_FORMAT } from 'constant'
import { store } from '../../../../store'
import { campaignEmissionScheduleActions } from '../store/campaign-emission-schedule-slice'

export default class CreationsFormScheduleService {
  static parseSchedule = (creationSchedule: CreationSchedule<string>): string => {
    const fromDate = new Date(creationSchedule.startDate)
    const toDate = new Date(creationSchedule.endDate)

    const from = DateUtils.parseAndFormat(fromDate, DATE_FORMAT)
    const to = DateUtils.parseAndFormat(toDate, DATE_FORMAT)
    const isSingleDay = from === to

    return `${from}${isSingleDay ? '' : ' - ' + to}`
  }

  static dateRangesToCreationSchedules = (dateRanges: DateRange[]): CreationSchedule[] => {
    return dateRanges.map((dateRange: DateRange) => ({
      startDate: dateRange.from!,
      endDate: dateRange.to!,
    }))
  }

  static creationSchedulesToDateRanges = (
    creationSchedules: CreationSchedule<string>[]
  ): DateRange[] => {
    return creationSchedules.map((creationSchedule: CreationSchedule<string>) => {
      const from = new Date(creationSchedule.startDate)
      const to = new Date(creationSchedule.endDate)

      return { from, to }
    })
  }

  static fetchCreationSchedules = ({
    creationId,
    creationUuid,
  }: {
    creationId?: Creation['id']
    creationUuid?: Creation['uuid']
  }): Promise<void> | void => {
    const setCreationSchedules = (creationSchedules: CreationSchedule<string>[]): void => {
      const mappedSchedules = creationSchedules.map(({ startDate, endDate }) => ({
        startDate,
        endDate,
      }))

      store.dispatch(campaignEmissionScheduleActions.setCreationSchedules(mappedSchedules))
      store.dispatch(campaignEmissionScheduleActions.setCreationSchedulesLoaded(true))
    }

    if (creationUuid) {
      return api.creation
        .getCreationSchedulesPublic(creationUuid)
        .then(res => void setCreationSchedules(res.data.creationSchedulesPublic.nodes))
        .catch(() => void {})
    }

    if (creationId) {
      return api.creation
        .getCreationSchedules(creationId)
        .then(res => void setCreationSchedules(res.data.creationSchedules.nodes))
        .catch(() => void {})
    }
  }

  static handleChangeCreationSchedules = (
    dateRanges: DateRange[],
    creationId: Creation['id'],
    currentCreationSchedules: CreationSchedule<string>[]
  ): void => {
    const creationScheduleDateRanges =
      CreationsFormScheduleService.creationSchedulesToDateRanges(currentCreationSchedules)

    const creationSchedulesHaveChanged = !VariableUtils.isDeepEqual(
      creationScheduleDateRanges,
      dateRanges
    )

    if (!creationSchedulesHaveChanged) {
      return
    }

    const creationSchedulesFromDatePicker =
      CreationsFormScheduleService.dateRangesToCreationSchedules(dateRanges)

    void CreationsFormScheduleService.updateCreationSchedules(
      creationId,
      creationSchedulesFromDatePicker
    )
  }

  static updateCreationSchedules = (
    creationId: Creation['id'],
    creationSchedules: CreationSchedule[]
  ): Promise<void> => {
    store.dispatch(campaignEmissionScheduleActions.setLoading({ key: 'schedule', value: true }))

    return api.creation
      .updateCreationSchedule(creationId, creationSchedules)
      .then(res => {
        const success = res.data?.updateCreationSchedule.success
        if (success) {
          const mappedCreationSchedules: CreationSchedule<string>[] = creationSchedules.map(
            (creationSchedule: CreationSchedule) => ({
              startDate: creationSchedule.startDate.toISOString(),
              endDate: creationSchedule.endDate.toISOString(),
            })
          )

          store.dispatch(
            campaignEmissionScheduleActions.setCreationSchedules(mappedCreationSchedules)
          )
        }
      })
      .catch(() => void {})
      .finally(() => {
        store.dispatch(
          campaignEmissionScheduleActions.setLoading({ key: 'schedule', value: false })
        )
      })
  }
}
