import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { InventoryProgressBarData } from './types'
import ProgressBar from 'components/ProgressBar'
import DateUtils from 'utils/date'
import { DATE_HOUR_FORMAT } from 'constant'
import FillingSpinner from 'components/FillingSpinner'
import service from './service'
import VariableUtils from 'utils/variable'
import { FilterContext } from 'components/Form/Filters'
import { FilterContextProps } from 'components/Form/Filters/FilterContext'
import { InventoryChartFilters } from 'pages/Inventory/models/types'
import { InventoryContext } from '../InventoryContext'
import './InventoryProgressBar.scss'
import { useSelector } from 'react-redux'
import { RootState } from '../../../store'
import { InventoryInContactsHelper } from '../helpers/inventory-in-contacts-helper.class'

const InventoryProgressBar: React.FC = () => {
  const { t } = useTranslation()
  const [data, setData] = useState<InventoryProgressBarData | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { filters } = useContext<FilterContextProps<InventoryChartFilters>>(FilterContext)
  const { target } = useSelector((state: RootState) => state.inventory.common.filters.selected)

  useEffect(() => {
    if (!target) {
      return
    }

    const inContacts = InventoryInContactsHelper.targetToInContacts(target)

    service.getData(setData, setIsLoading, filters, inContacts)
  }, [useContext(InventoryContext).filtersDebouncedCounter, target])

  const calculatePercentage = (
    allocated: InventoryProgressBarData['allocatedSlots'],
    free: InventoryProgressBarData['freeSlots']
  ): number => {
    const sum = allocated + free
    return sum === 0 ? 0 : (allocated / sum) * 100
  }

  const Container = (data: InventoryProgressBarData) => (
    <div className='InventoryProgressBar__container'>
      <div className='InventoryProgressBar__container--last-update'>
        {t('inventory.lastUpdate')}: {DateUtils.parseAndFormat(data.lastUpdate, DATE_HOUR_FORMAT)}
      </div>

      <ProgressBar percent={calculatePercentage(data.allocatedSlots, data.freeSlots)} />

      <div className='InventoryProgressBar__quantities'>
        <div>
          {target === 'audience'
            ? t('inventory.audienceUsed')
            : t('inventory.allocatedSecondsQuantity')}
          <div>
            {VariableUtils.formatNumber(data.allocatedSlots)} {t('common.pcs')}
          </div>
        </div>

        <div>
          {target === 'audience'
            ? t('inventory.audiencePotential')
            : t('inventory.freeSecondsQuantity')}
          <div>
            {VariableUtils.formatNumber(data.freeSlots)} {t('common.pcs')}
          </div>
        </div>
      </div>
    </div>
  )

  return (
    <div className='InventoryProgressBar'>
      {isLoading ? <FillingSpinner /> : data ? Container(data) : t('common.noDataFound')}
    </div>
  )
}

export default InventoryProgressBar
