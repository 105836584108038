import { FormCreation, CreationsValues } from 'contexts/CampaignFormContext'
import EnumUtils from 'utils/enum'
import { Campaign, Creation, CreationStatus, MediaFormat } from 'types/campaign'
import api from 'api'
import { CardStatus } from 'components/Layout/Card/types'
import { campaignFormActions } from '../../store/campaign-form-slice'
import { store } from '../../../../store'

export default class CreationsFormService {
  static getCreations = (
    campaignId: Campaign['id'],
    creationsLocal: CreationsValues,
    setCreations: React.Dispatch<React.SetStateAction<CreationsValues>>,
    setTriggerCreations: React.Dispatch<React.SetStateAction<boolean>>
  ): void => {
    store.dispatch(campaignFormActions.setCardStatus({ creations: CardStatus.LOADING }))

    api.campaign
      .getCampaignForCreationsForm(campaignId)
      .then(res => {
        const { creations: creationsResponse } = res.data.node

        const mappedCreations: FormCreation[] = creationsResponse
          .map((creation: Creation) => {
            const localCreation = creationsLocal.find(
              localCreation => localCreation.id === creation.id
            )

            const getThumbnailUrl = () => {
              if (!creation.thumbnailUrl && localCreation) {
                return localCreation.thumbnailUrl
              }

              return creation.thumbnailUrl
            }

            return {
              id: creation.id,
              archiveReason: creation.archiveReason,
              details: creation.details,
              mediaFormat: MediaFormat[creation.mediaFormat as unknown as keyof typeof MediaFormat],
              thumbnailUrl: getThumbnailUrl(),
              url: creation.file.url,
              isVideo: creation.file.isVideo,
              filename: creation.file.filename,
              approved: creation.approved,
              archivedAt: creation.archivedAt,
              state: CreationStatus[EnumUtils.getKeyByValueT(CreationStatus, creation.state)!],
              emissionStartDate: creation.emissionStartDate,
              alerts: creation.alerts,
              duration: creation.duration,
              createdAt: creation.createdAt,
              schedules: creation.schedules,
              impressions: creation.impressions,
              containsAlcohol: creation.containsAlcohol,
              audience: creation.audience,
            }
          })
          .sort((a, b) => {
            // sort by date, the oldest first
            return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
          })

        const cardStatus = mappedCreations.some(creation => creation.alerts.length)
          ? CardStatus.ERROR
          : CardStatus.NONE

        setCreations(mappedCreations)
        store.dispatch(campaignFormActions.setCardStatus({ creations: cardStatus }))
      })
      .catch(() => {
        store.dispatch(campaignFormActions.setCardStatus({ creations: CardStatus.ERROR }))
      })
      .finally(() => void setTriggerCreations(false))
  }
}
