import { Agency } from '../../../types/agency'
import { BaseRole, Role } from '../../../types/user'
import { InventoryTarget } from '../models/inventory-common-slice.model'

export class InventoryInContactsHelper {
  public static targetToInContacts = (option: string): boolean => {
    return option === 'audience'
  }

  public static getOptions = (userRoles: Role[], agencies: Agency[]): InventoryTarget[] => {
    if (!userRoles || !userRoles.length) {
      return []
    }
    // Admins can always see the contacts
    if (userRoles.some(role => role.baseRole === BaseRole.ADMIN)) {
      return ['impressions', 'audience']
    }

    if (!agencies || !agencies.length) {
      return []
    }

    let allowedOptions: InventoryTarget[] = []

    // if any agency has in allowedTargets an object which name property is 'impressions', then the user can see the contacts
    if (
      agencies.some(agency => agency.allowedTargets.some(target => target.name === 'impressions'))
    ) {
      allowedOptions = [...allowedOptions, 'impressions']
    }

    // if any agency has in allowedTargets an object which name property is 'audience', then the user can see the contacts
    if (agencies.some(agency => agency.allowedTargets.some(target => target.name === 'audience'))) {
      allowedOptions = [...allowedOptions, 'audience']
    }

    return allowedOptions
  }
}
