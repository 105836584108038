import React, { useCallback, useEffect, useState } from 'react'
import FlexRow from '../../../../../components/Layout/FlexRow'
import FlexCol from '../../../../../components/Layout/FlexCol'
import Preview from '../../../../../components/Preview'
import VariableUtils from '../../../../../utils/variable'
import './CreationsFormAudienceNumber.scss'
import Input from '../../../../../components/Form/Input'
import { InputMask } from '../../../../../utils/inputMask'
import { useTranslation } from 'react-i18next'
import service from '../../services/creations-form-audience.service'
import debounce from 'lodash/debounce'
import { DEBOUNCE_POST_DELAY_DEFAULT } from '../../../../../constant'
import CreationsAudienceTooltipAlert from '../../../CampaignForm/CreationsForm/CreationAudienceTooltipAlert'
import { CreationsFormAudienceNumberProps } from '../../models/types'
import { useDispatch, useSelector } from 'react-redux'
import { campaignEmissionScheduleActions } from '../../store/campaign-emission-schedule-slice'
import { RootState } from '../../../../../store'

const CreationsFormAudienceNumber: React.FC<CreationsFormAudienceNumberProps> = ({
  creationId,
  creations,
  campaignAudience,
}) => {
  const dispatch = useDispatch()
  const [errors, setErrors] = useState<string[]>([])
  const { t } = useTranslation()
  const creation = creations.find(c => c.id === creationId)!
  const { readOnly, emissionNumber } = useSelector(
    (state: RootState) => state.campaignEmissionSchedule
  )

  const debouncedCall = useCallback(
    debounce(
      audienceNumber =>
        service.updateCreationAudienceNumber(creation.id, parseInt(audienceNumber), setErrors, t),
      DEBOUNCE_POST_DELAY_DEFAULT
    ),
    []
  )

  useEffect(() => {
    setErrors([])

    if (readOnly) {
      return
    }
    debouncedCall(emissionNumber.audienceNumber)
  }, [emissionNumber.audienceNumber, readOnly])

  useEffect(() => {
    if (creation.audience) {
      dispatch(
        campaignEmissionScheduleActions.setEmissionsNumber({
          key: 'audienceNumber',
          value: creation.audience.toString(),
        })
      )
    }
  }, [creation])

  return (
    <FlexRow className='CreationsFormAudienceNumber--row'>
      <FlexCol>
        <Preview
          className={'CreationsFormAudienceNumber--preview'}
          src={creation.file.url}
          thumbnailSrc={creation.thumbnailUrl}
          isVideo={creation.file.isVideo}
          alt={creation.file.filename}
        />
      </FlexCol>

      <FlexCol>
        <div className={'CreationsFormAudienceNumber__emissions'}>
          <p>
            {t('form.campaign.details.target')}:
            <span className={'CreationsFormAudienceNumber__emissions--number'}>
              &nbsp;{VariableUtils.formatNumber(campaignAudience)}
            </span>
          </p>
          <div className={'CreationsFormAudienceNumber__emissions--label'}>
            <span>
              {t('form.campaign.creations.creationAudience')}:&nbsp;
              <b>{creation.file.filename}</b>
            </span>
            <CreationsAudienceTooltipAlert
              creations={creations}
              currentCreationId={creation.id}
              campaignAudience={campaignAudience}
              creationAudienceNumber={emissionNumber.audienceNumber}
            />
          </div>
          <Input
            id='audience'
            title={''}
            help={t('form.campaign.details.emissionsHelp')}
            dataCy={'number-of-audience-emissions'}
            value={emissionNumber.audienceNumber}
            onChange={(value: string) => {
              dispatch(
                campaignEmissionScheduleActions.setEmissionsNumber({ key: 'audienceNumber', value })
              )
            }}
            maskType={InputMask.INTEGER}
            errors={errors}
            readOnly={!!readOnly}
          />
        </div>
      </FlexCol>
    </FlexRow>
  )
}

export default CreationsFormAudienceNumber
