import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { CampaignFormSliceModel } from '../models/campaign-form-slice.model'
import { basicsValuesDefault, detailsValuesDefault } from '../../../contexts/CampaignFormContext'
import { produce } from 'immer'
import { CardStatus } from '../../../components/Layout/Card'

const defaultState: CampaignFormSliceModel = {
  cardStatus: {
    basics: CardStatus.NONE,
    details: CardStatus.NONE,
    creations: CardStatus.NONE,
  },
  form: {
    basicsValues: basicsValuesDefault,
    detailsValues: detailsValuesDefault,
  },
  numberOfUsedAgglomerations: 0,
  suggestions: {
    loading: false,
    suggestedAudience: 0,
    suggestedImpressions: 0,
  },
}

const initialState: CampaignFormSliceModel = produce(defaultState, draft => draft)

const campaignFormSlice = createSlice({
  name: 'campaignForm',
  initialState,
  reducers: {
    updateBasicsValues(
      state,
      action: PayloadAction<Partial<CampaignFormSliceModel['form']['basicsValues']>>
    ) {
      state.form.basicsValues = produce(state.form.basicsValues, draft => {
        return { ...draft, ...action.payload }
      })
    },
    updateDetailsValues(
      state,
      action: PayloadAction<Partial<CampaignFormSliceModel['form']['detailsValues']>>
    ) {
      state.form.detailsValues = produce(state.form.detailsValues, draft => {
        return { ...draft, ...action.payload }
      })
    },
    resetCampaignForm() {
      return defaultState
    },
    setCardStatus(state, action: PayloadAction<Partial<CampaignFormSliceModel['cardStatus']>>) {
      state.cardStatus = produce(state.cardStatus, draft => {
        return { ...draft, ...action.payload }
      })
    },
    setNumberOfUsedAgglomerations(state, action: PayloadAction<number>) {
      state.numberOfUsedAgglomerations = action.payload
    },
    setSuggestions(
      state,
      action: PayloadAction<{
        suggestedAudience: CampaignFormSliceModel['suggestions']['suggestedAudience']
        suggestedImpressions: CampaignFormSliceModel['suggestions']['suggestedImpressions']
      }>
    ) {
      state.suggestions = produce(state.suggestions, draft => {
        return { ...draft, ...action.payload }
      })
    },
    setSuggestionsLoading(state, action: PayloadAction<boolean>) {
      state.suggestions.loading = action.payload
    },
  },
})

export const campaignFormActions = campaignFormSlice.actions

export default campaignFormSlice
