import { EmissionSchedulePublicRouteParams, EmissionScheduleRouteParams } from './types'

const READ_ONLY_ROUTE = 'ro'
const emissionScheduleRoute = ({ campaignId, creationId }: EmissionScheduleRouteParams): string =>
  `/campaign/${campaignId}/creation/${creationId}/emission-schedule`

const Routes = {
  ANY: '*',
  NOT_FOUND: '/404',
  ROOT: '/',
  HOME: '/home',
  REPORT: '/report',
  INVENTORY: '/inventory',
  FEATURES: '/features',
  EMISSION_PLAN: (uuid: string): string => `/emission-plan/${uuid}`,

  SIGN: {
    SIGN_IN: '/sign-in',
    REMIND_PASSWORD: '/remind-password',
    RESET_PASSWORD: '/passwords/reset',
    ACCEPT_INVITATION: '/accept-invitation',
  },

  CAMPAIGNS: {
    ALL: '/campaigns',
    NEW: '/campaign/new',
    EDIT: (id: string, hash?: string): string => `/campaign/${id}/edit${hash ? '#' + hash : ''}`,
    ONE: (id: string): string => `/campaign/${id}`,
    ONE_PUBLIC: (uuid: string): string => `/campaign-public/${uuid}`,
    ACCEPTANCE_FIELDS: (id: string): string => `/campaign/${id}/acceptance-fields`,
    EMISSION_SCHEDULE: (args: EmissionScheduleRouteParams) => emissionScheduleRoute(args),
    EMISSION_SCHEDULE_RO: (args: EmissionScheduleRouteParams) =>
      `${emissionScheduleRoute(args)}/${READ_ONLY_ROUTE}`,
    EMISSION_SCHEDULE_PUBLIC: ({
      campaignUuid,
      creationUuid,
    }: EmissionSchedulePublicRouteParams): string =>
      `/campaign-public/${campaignUuid}/creation-public/${creationUuid}/emission-schedule`,
  },

  AGENCIES: {
    ALL: '/agencies',
    NEW: '/agency/new',
    EDIT: (id: string): string => `/agency/${id}/edit`,
  },

  USERS: {
    ALL: '/users',
    INVITE: '/user/invite',
    EDIT: (id: string): string => `/user/${id}/edit`,
    SETTINGS: '/user/settings',
  },
  ROLES: {
    ALL: '/roles',
    NEW: '/roles/new',
    EDIT: (id: string): string => `/roles/${id}/edit`,
  },
}

export default Routes
