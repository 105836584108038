import api from '../../../../api'
import { TFunction } from 'react-i18next'
import { store } from '../../../../store'
import { campaignEmissionScheduleActions } from '../store/campaign-emission-schedule-slice'

export default class CreationAudienceNumberService {
  static updateCreationAudienceNumber = (
    creationId: string,
    audience: number,
    setErrors: React.Dispatch<React.SetStateAction<string[]>>,
    t: TFunction
  ) => {
    store.dispatch(campaignEmissionScheduleActions.setEmissionsNumberLoading(true))

    api.creation
      .updateCreationAudienceNumber(creationId, audience)
      .catch(errors => {
        if (errors) {
          setErrors([t('form.campaign.creations.invalidCreationEmissions')])
        }
      })
      .finally(() => {
        store.dispatch(campaignEmissionScheduleActions.setEmissionsNumberLoading(false))
      })
  }
}
