import React, { useEffect, useState } from 'react'
import MediaSelection from 'components/MediaSelection/MediaSelection'
import './CreationsFormLocations.scss'
import { CreationsFormLocationsProps } from '../../models/types'
import { useDispatch } from 'react-redux'
import { campaignEmissionScheduleActions } from '../../store/campaign-emission-schedule-slice'

const CreationsFormLocations: React.FC<CreationsFormLocationsProps> = ({
  ids,
  mediumFormat,
  readOnly,
  onChange,
}) => {
  const dispatch = useDispatch()
  const [locationsLoading, setLocationsLoading] = useState<boolean>(false)
  const [shouldSaveMedia, setShouldSaveMedia] = useState<boolean>(false)

  useEffect(() => {
    setLocationsLoading(shouldSaveMedia)

    if (shouldSaveMedia) {
      onChange()
    }
  }, [shouldSaveMedia])

  useEffect(() => {
    dispatch(campaignEmissionScheduleActions.setLocationsLoading(locationsLoading))
  }, [locationsLoading])

  return (
    <MediaSelection
      ids={ids}
      mediumFormat={mediumFormat}
      readOnly={readOnly}
      shouldSave={shouldSaveMedia}
      setShouldSave={setShouldSaveMedia}
      withFilters
      autoSave={true}
      onLoading={setLocationsLoading}
    />
  )
}

export default CreationsFormLocations
