import { gql } from '@apollo/client'

export const GET_CAMPAIGN_SUGGESTED_AUDIENCE = gql`
  query campaignSuggestedAudience($campaignId: ID!) {
    campaignSuggestedAudience(campaignId: $campaignId) {
      errors
      suggestedAudience
      suggestedImpressions
    }
  }
`
