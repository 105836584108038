import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BasicFormErrors, BasicFormErrorsEmpty } from './types'
import DatePickerSingle from 'components/Form/DatePickerSingle'
import FormColumn from 'components/Form/FormColumn'
import FormRow from 'components/Form/FormRow'
import Input, { OnInputChangeFn } from 'components/Form/Input'
import SelectAsync from 'components/Form/SelectAsync'
import service from './basics-form.service'
import {
  BasicsValues,
  basicsValuesDefault,
  CampaignFormContext,
} from 'contexts/CampaignFormContext'
import usePrevious from 'hooks/usePrevious'
import VariableUtils from 'utils/variable'
import { useNavigate, useParams } from 'react-router-dom'
import { ReactRouterParams } from 'types/various'
import { DATE_PLACEHOLDER } from 'constant'
import { AppContext } from 'contexts/AppContext'
import { CAMPAIGN_FORM } from 'constant/authorization'
import DateUtils from 'utils/date'
import debounce from 'lodash/debounce'
import { Campaign, CampaignStatus } from 'types/campaign'
import './BasicsForm.scss'
import { BaseRole } from '../../../../types/user'
import { useSelector } from 'react-redux'
import { campaignFormActions } from '../../store/campaign-form-slice'
import { RootState, store } from '../../../../store'

const DEBOUNCE_POST_DELAY = 1000 // [ms]

const BasicsForm: React.FC = () => {
  const { t } = useTranslation()
  const { allowedFor, userData } = useContext(AppContext)
  const navigate = useNavigate()
  const { triggerFetchCampaign, setTriggerFetchCampaign } = useContext(CampaignFormContext)
  const { id: urlId } = useParams<ReactRouterParams>()
  const [errors, setErrors] = useState<BasicFormErrors>(BasicFormErrorsEmpty)
  const [fetchCompleted, setFetchCompleted] = useState<boolean>(false)
  const [canUpdate, setCanUpdate] = useState<boolean>(false)
  const { basicsValues, detailsValues } = useSelector((state: RootState) => state.campaignForm.form)
  const previousValues = usePrevious<BasicsValues>(basicsValues)

  const campaignId = basicsValues.campaignId
  const campaignStatus = basicsValues.status

  const onInputChange: OnInputChangeFn = (input, id, extraFields): void => {
    store.dispatch(
      campaignFormActions.updateBasicsValues({
        ...basicsValues,
        [id ?? '']: input,
        ...(extraFields ? Object.assign({}, ...extraFields.map(e => ({ [e.id]: e.value }))) : {}),
      })
    )
  }

  const debouncedHandleSubmit = useCallback(debounce(service.handleSubmit, DEBOUNCE_POST_DELAY), [])

  const debouncedHandleUpdate = useCallback(debounce(service.handleUpdate, DEBOUNCE_POST_DELAY), [])

  const fetchValues = (campaignId: Campaign['id']): void =>
    service.fetchValues(
      campaignId,
      basicsValues,
      setFetchCompleted,
      setTriggerFetchCampaign,
      allowedFor,
      userData.user?.roles,
      navigate,
      userData.withAllocatedTime,
      t
    )

  const isNotAllowedDate = (
    day: Date,
    dateTo: string,
    dateFrom: string,
    isDateFromDatePicker: boolean,
    reservationDate?: Date | null
  ): boolean => {
    if (reservationDate) {
      return DateUtils.isBeforeDay(day, new Date()) || DateUtils.isBeforeDay(day, reservationDate)
    }
    if (DateUtils.isBeforeDay(day, new Date())) {
      return true
    }
    if (isDateFromDatePicker) {
      return day > new Date(dateTo)
    }

    return day < new Date(dateFrom)
  }

  const disabledSupervisors = (): boolean => {
    if (userData.user?.roles.some(role => role.baseRole === BaseRole.SUPPORT)) {
      return false
    }

    return basicsValues.agency === null || !basicsValues.campaignId
  }
  const disabledAgency = (): boolean => campaignStatus === CampaignStatus.ACTIVE

  useEffect(() => {
    if (urlId && triggerFetchCampaign) {
      fetchValues(urlId)
    }
  }, [triggerFetchCampaign])

  useEffect(() => {
    if (urlId && !fetchCompleted) {
      fetchValues(urlId)
    } else {
      setFetchCompleted(true)
    }
  }, [urlId, fetchCompleted])

  useEffect(() => {
    if (previousValues && basicsValues) {
      const notTouched = VariableUtils.isDeepEqual(
        { ...previousValues, campaignId: null, brainState: null },
        { ...basicsValues, campaignId: null, brainState: null }
      )
      const differentThanDefaults = !VariableUtils.isDeepEqual(previousValues, basicsValuesDefault)

      if (notTouched) {
        setCanUpdate(false)
      } else {
        service.validate(basicsValues, allowedFor, setErrors, errors, t)

        if (fetchCompleted && differentThanDefaults) {
          setCanUpdate(true)
        }
      }
    }
  }, [previousValues, basicsValues, fetchCompleted])

  useEffect(() => {
    if (!service.hasErrors(errors) && fetchCompleted) {
      if (!campaignId) {
        void debouncedHandleSubmit(
          basicsValues,
          onInputChange,
          navigate,
          detailsValues,
          setTriggerFetchCampaign,
          userData.user?.roles
        )
      } else if (canUpdate) {
        debouncedHandleUpdate(basicsValues, t, userData.user?.roles)
      }
    }
  }, [debouncedHandleSubmit, debouncedHandleUpdate, errors, canUpdate])

  return (
    <div className='BasicsForm'>
      <FormRow className='BasicsForm__row'>
        <FormColumn>
          <Input
            id='name'
            title={t('form.campaign.basics.name')}
            placeholder={t('form.campaign.basics.namePlaceholder')}
            value={basicsValues.name}
            onChange={(input, id): void => {
              if (userData.withAllocatedTime) {
                onInputChange(input, id, [{ id: 'briefName', value: input }])
                return
              }

              onInputChange(input, id)
            }}
            errors={errors?.name}
            disabled={!allowedFor({ template: CAMPAIGN_FORM.NAME, status: campaignStatus })}
          />
        </FormColumn>
      </FormRow>

      {!userData.withAllocatedTime && (
        <FormRow className='BasicsForm__row'>
          <FormColumn>
            <Input
              id='briefName'
              title={t('common.briefName')}
              placeholder={t('form.campaign.basics.briefNamePlaceholder')}
              value={basicsValues.briefName}
              onChange={onInputChange}
              errors={errors?.briefName}
              disabled={!allowedFor({ template: CAMPAIGN_FORM.BRIEF_NAME, status: campaignStatus })}
            />
          </FormColumn>
        </FormRow>
      )}

      {allowedFor({ template: CAMPAIGN_FORM.AGENCY, status: campaignStatus }) && (
        <FormRow className='BasicsForm__row'>
          <SelectAsync
            id='agency'
            title={t('common.agency')}
            help={t('form.campaign.basics.agencyHelp')}
            placeholder={t('form.campaign.basics.agencyNamePlaceholder')}
            value={basicsValues.agency}
            options={service.getAgencies}
            onChange={(agency, id): void => {
              onInputChange(agency, id)
            }}
            errors={errors?.agency}
            disabled={disabledAgency()}
          />
        </FormRow>
      )}

      {!userData.withAllocatedTime && (
        <FormRow className='BasicsForm__row'>
          <SelectAsync
            id='advertiser'
            title={t('common.advertiser')}
            help={t('form.campaign.basics.advertiserHelp')}
            placeholder={t('common.advertiser')}
            value={basicsValues.advertiser}
            isClearable={true}
            label={t('common.search') + '...'}
            options={service.getCompanies}
            onChange={(advertiser, id): void => onInputChange(advertiser, id)}
            asyncFiltering={true}
          />
        </FormRow>
      )}

      {allowedFor({ template: CAMPAIGN_FORM.SUPERVISORS, status: campaignStatus }) && (
        <FormRow className='BasicsForm__row'>
          <SelectAsync
            id='supervisors'
            title={t('common.supervisors')}
            placeholder={t('form.campaign.basics.supervisorsPlaceholder')}
            value={basicsValues.supervisors}
            onChange={onInputChange}
            options={service.getUsersForSupervisors}
            multi
            closeOnSelect={false}
            disabled={disabledSupervisors()}
          />
        </FormRow>
      )}

      <FormRow className='BasicsForm__row'>
        <FormColumn className='BasicsForm__date-input--column'>
          <DatePickerSingle
            id='startDate'
            value={basicsValues.startDate}
            title={t('form.campaign.basics.startDate')}
            placeholder={DATE_PLACEHOLDER}
            isOutsideRange={day => {
              return isNotAllowedDate(
                day,
                basicsValues.endDate,
                basicsValues.startDate,
                true,
                basicsValues.reservationTill
              )
            }}
            onChange={onInputChange}
            errors={errors?.startDate}
            disabled={!allowedFor({ template: CAMPAIGN_FORM.START_DATE, status: campaignStatus })}
          />
        </FormColumn>

        <FormColumn className='BasicsForm__date-input--column'>
          <DatePickerSingle
            id='endDate'
            value={basicsValues.endDate}
            title={t('form.campaign.basics.endDate')}
            placeholder={DATE_PLACEHOLDER}
            isOutsideRange={day =>
              isNotAllowedDate(day, basicsValues.endDate, basicsValues.startDate, false)
            }
            onChange={onInputChange}
            errors={errors?.endDate}
            disabled={!allowedFor({ template: CAMPAIGN_FORM.END_DATE, status: campaignStatus })}
          />
        </FormColumn>
      </FormRow>
    </div>
  )
}

export default BasicsForm
