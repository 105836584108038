import api from 'api'
import { Filters, FiltersForm, filtersFormDefault } from './types'
import { setFilters, setLoading } from './slice'
import { AppDispatch } from 'store'
import pick from 'lodash/pick'

export default class CampaignTableFiltersService {
  static getFilters = (dispatch: AppDispatch): void => {
    dispatch(setLoading(true))

    api.campaign
      .getCampaignFilters()
      .then(res => {
        const {
          cities,
          agencies,
          mediaFormats,
          statuses,
          targets,
          commercialAttributes,
          buildings,
        } = res.data.campaignFilters

        dispatch(
          setFilters({
            ...filtersFormDefault,
            citiesAll: cities,
            agenciesAll: agencies,
            mediaFormatsAll: mediaFormats,
            statusesAll: statuses,
            targetsAll: targets,
            commercialAttributesAll: commercialAttributes,
            buildingsAll: buildings,
          })
        )
      })
      .catch(() => void {})
      .finally(() => void dispatch(setLoading(false)))
  }

  static cleanFiltersForm = (previousFilters: FiltersForm, dispatch: AppDispatch): void => {
    const {
      citiesAll,
      agenciesAll,
      mediaFormatsAll,
      statusesAll,
      commercialAttributesAll,
      targetsAll,
      buildingsAll,
    } = previousFilters

    dispatch(
      setFilters({
        ...filtersFormDefault,
        citiesAll,
        agenciesAll,
        mediaFormatsAll,
        statusesAll,
        commercialAttributesAll,
        targetsAll,
        buildingsAll,
      })
    )
  }

  static pickOnlyApiFilters = (filters: FiltersForm): Filters => {
    return pick(filters, [
      'alerts',
      'cities',
      'agencies',
      'endDateFrom',
      'endDateTo',
      'mediaFormats',
      'startDateFrom',
      'startDateTo',
      'statuses',
      'text',
      'targets',
      'commercialAttributes',
      'buildings',
    ])
  }

  static areFiltersFetched = (filters: FiltersForm): boolean => {
    const { citiesAll, agenciesAll, mediaFormatsAll, statusesAll } = filters
    const {
      citiesAll: citiesAllDefault,
      agenciesAll: agenciesAllDefault,
      mediaFormatsAll: mediaFormatsAllDefault,
      statusesAll: statusesAllDefault,
    } = filtersFormDefault

    return (
      citiesAll === citiesAllDefault &&
      agenciesAll === agenciesAllDefault &&
      mediaFormatsAll === mediaFormatsAllDefault &&
      statusesAll === statusesAllDefault
    )
  }
}
