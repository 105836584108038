import React, { useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import service from '../../services/creations-form-schedule.service'
import DatePickerRange, { DateRange } from 'components/Form/DatePickerRange'
import { CreationSchedule } from 'types/campaign'
import FillingSpinner from 'components/FillingSpinner'
import './CreationsFormSchedule.scss'
import debounce from 'lodash/debounce'
import { CreationsFormScheduleModel } from '../../models/creations-form-schedule.model'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../store'

const DEBOUNCE_UPDATE_CREATION_DELAY = 200

const CreationsFormSchedule: React.FC<CreationsFormScheduleModel> = ({
  campaignStartDate,
  campaignEndDate,
  creationId,
  creationUuid,
  readOnly,
}) => {
  const { t } = useTranslation()
  const { schedule } = useSelector((state: RootState) => state.campaignEmissionSchedule)

  const mappedCreationSchedules = useMemo(
    () =>
      schedule.creationSchedules
        ? service.creationSchedulesToDateRanges(schedule.creationSchedules)
        : [],
    [schedule.creationSchedules]
  )

  const debouncedChangeCreationSchedules = useCallback(
    debounce(service.handleChangeCreationSchedules, DEBOUNCE_UPDATE_CREATION_DELAY),
    []
  )

  useEffect(() => {
    if (creationId || creationUuid) {
      service.fetchCreationSchedules({ creationId, creationUuid })
    }
  }, [creationId, creationUuid])

  return !schedule.loaded ? (
    <FillingSpinner />
  ) : (
    <div className='CreationsFormSchedule__calendar'>
      <DatePickerRange
        disabledDays={{
          from: new Date(Date.parse(campaignStartDate)),
          to: new Date(Date.parse(campaignEndDate)),
        }}
        values={mappedCreationSchedules}
        onChange={(dateRanges: DateRange[]) => {
          if (creationId && !readOnly && schedule.loaded) {
            debouncedChangeCreationSchedules(dateRanges, creationId, schedule.creationSchedules)
          }
        }}
        readOnly={readOnly || schedule.loading}
        loading={schedule.loading}
      />

      <div className='CreationsFormSchedule__list'>
        <div className='CreationsFormSchedule__list--header'>
          {t('form.campaign.creations.schedules.selectedDates')}:
        </div>

        {schedule.creationSchedules.map((creationSchedule: CreationSchedule<string>, i: number) => (
          <div
            key={i}
            className='CreationsFormSchedule__list--item'
          >
            {service.parseSchedule(creationSchedule)}
          </div>
        ))}
      </div>
    </div>
  )
}

export default CreationsFormSchedule
