import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { produce } from 'immer'
import { InventoryCommonSliceModel } from '../models/inventory-common-slice.model'

const defaultState: InventoryCommonSliceModel = {
  filters: {
    all: {
      targets: [],
    },
    selected: {
      target: 'impressions',
    },
  },
}

const initialState: InventoryCommonSliceModel = produce(defaultState, draft => draft)

const inventoryCommonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    setAllTargets(
      state,
      action: PayloadAction<InventoryCommonSliceModel['filters']['all']['targets']>
    ) {
      state.filters.all.targets = action.payload
    },
    setSelectedTarget(
      state,
      action: PayloadAction<InventoryCommonSliceModel['filters']['selected']['target']>
    ) {
      state.filters.selected.target = action.payload
    },
    resetState() {
      return defaultState
    },
  },
})

export const inventoryCommonActions = inventoryCommonSlice.actions

export default inventoryCommonSlice
