import React, { ReactElement, useCallback, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import PageHeader from 'components/Layout/PageHeader'
import CardHeader from 'components/Layout/CardHeader'
import Card, { CardStatus } from 'components/Layout/Card'
import { PrivateViewIds, PublicViewIds } from './models/types'
import { EmissionScheduleRouteParams } from 'routes'
import { useNavigate, useParams } from 'react-router-dom'
import FillingSpinner from 'components/FillingSpinner'
import TooltipHelp from 'components/TooltipHelp'
import Button from 'components/Form/Button'
import { AppContext } from 'contexts/AppContext'
import { EMISSION_SCHEDULE } from 'constant/authorization'
import MediaMap from 'components/MediaMap'
import useFilteredMedia from 'hooks/useFilteredMedia'
import debounce from 'lodash/debounce'
import { DEBOUNCE_POST_DELAY_DEFAULT } from 'constant'
import './EmissionSchedule.scss'
import { CampaignTarget } from '../../../types/campaign'
import { CampaignEmissionScheduleTooltip } from './components/campaign-emission-schedule-tooltip'
import CreationsFormSchedule from './containers/CreationsFormSchedule/CreationsFormSchedule'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../store'
import { campaignEmissionScheduleActions } from './store/campaign-emission-schedule-slice'
import EmissionScheduleService from './services/emission-schedule.service'
import { EmissionScheduleCampaignData } from './models/emission-schedule-campaign-data.model'
import CreationsFormEmissionNumber from './containers/CreationsFormEmissionNumber/CreationsFormEmissionNumber'
import CreationsFormLocations from './containers/CreationsFormLocations/CreationsFormLocations'
import CreationsFormAudienceNumber from './containers/CreationsFormAudienceNumber/CreationsFormAudienceNumber'

const EmissionSchedule: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { allowedFor } = useContext(AppContext)
  const navigate = useNavigate()
  const {
    campaignId: urlCampaignId,
    campaignUuid: urlCampaignUuid,
    creationId: urlCreationId,
    creationUuid: urlCreationUuid,
    readOnly: urlReadOnly,
  } = useParams<EmissionScheduleRouteParams>()
  const { readOnly, campaignData, schedule, locations, emissionNumber, map } = useSelector(
    (state: RootState) => state.campaignEmissionSchedule
  )
  const [triggerFetchCreationMedia, setTriggerFetchCreationMedia] = useState<boolean>(false)

  const privateViewIds: PrivateViewIds | undefined =
    urlCampaignId && urlCreationId ? { urlCampaignId, urlCreationId } : undefined
  const publicViewIds: PublicViewIds | undefined =
    urlCampaignUuid && urlCreationUuid ? { urlCampaignUuid, urlCreationUuid } : undefined

  const { fetchCreationMedia, mediaLoading, media } = useFilteredMedia()

  const debouncedFetchCreationMedia = useCallback(
    debounce(fetchCreationMedia, DEBOUNCE_POST_DELAY_DEFAULT),
    []
  )

  useEffect(() => {
    const readOnly = !!urlCampaignUuid || !!urlReadOnly
    dispatch(campaignEmissionScheduleActions.setReadOnly(readOnly))
  }, [urlCampaignUuid, urlReadOnly])

  useEffect(() => {
    if (privateViewIds || publicViewIds) {
      void EmissionScheduleService.getCampaignData(privateViewIds, publicViewIds, navigate)
    }
  }, [urlCampaignId, urlCreationId, urlCampaignUuid, urlCreationUuid])

  useEffect(() => {
    if ((campaignData || triggerFetchCreationMedia) && map.show) {
      debouncedFetchCreationMedia({
        creationId: privateViewIds?.urlCreationId,
        creationUuid: publicViewIds?.urlCreationUuid,
      })

      return () => void setTriggerFetchCreationMedia(false)
    }
  }, [campaignData, triggerFetchCreationMedia, debouncedFetchCreationMedia, map.show])

  useEffect(() => {
    // Reset state on unmount/destroy
    return () => {
      dispatch(campaignEmissionScheduleActions.resetState())
    }
  }, [])

  const Container = (campaignData: EmissionScheduleCampaignData): ReactElement => {
    const selectedCreation = campaignData.creations.find(
      creation => creation.id === urlCreationId || creation.uuid === urlCreationUuid
    )!

    const isScheduleReadOnly: boolean =
      readOnly ||
      !(
        allowedFor({
          template: EMISSION_SCHEDULE.SCHEDULE_BY_CAMPAIGN_STATUS,
          status: campaignData?.status,
        }) &&
        allowedFor({
          template: EMISSION_SCHEDULE.SCHEDULE_BY_CREATION_STATUS,
          status: selectedCreation.state,
        })
      )

    const isMediaReadOnly: boolean =
      readOnly ||
      Boolean(selectedCreation.archivedAt) ||
      !(
        allowedFor({
          template: EMISSION_SCHEDULE.LOCATIONS_BY_CAMPAIGN_STATUS,
          status: campaignData?.status,
        }) &&
        allowedFor({
          template: EMISSION_SCHEDULE.LOCATIONS_BY_CREATION_STATUS,
          status: selectedCreation.state,
        })
      )

    return (
      <div className='EmissionSchedule'>
        <div className='EmissionSchedule__header'>
          <PageHeader>
            {t(`${readOnly ? 'common.emissionSchedule' : 'emissionSchedule.titleEdit'}`) +
              ' - ' +
              selectedCreation.file.filename}
          </PageHeader>

          {!readOnly && (
            <TooltipHelp
              className='EmissionSchedule__tooltip'
              containerClassName='EmissionSchedule__tooltip--a'
            >
              {t('emissionSchedule.info')}
            </TooltipHelp>
          )}
        </div>

        <Card>
          <CardHeader
            number={1}
            collapsed={!schedule.show}
            onCollapse={() => {
              dispatch(campaignEmissionScheduleActions.toggleShow('schedule'))
            }}
          >
            <div className='EmissionSchedule__header'>
              {t('emissionSchedule.duration')}
              {!readOnly && <CampaignEmissionScheduleTooltip />}
            </div>
          </CardHeader>

          {schedule.show && (
            <div className='EmissionSchedule__schedule'>
              <CreationsFormSchedule
                campaignStartDate={campaignData.startDate}
                campaignEndDate={campaignData.endDate}
                creationId={urlCreationId}
                creationUuid={urlCreationUuid}
                readOnly={isScheduleReadOnly}
              />
            </div>
          )}
        </Card>

        <Card>
          <CardHeader
            number={2}
            collapsed={!locations.show}
            onCollapse={() => {
              dispatch(campaignEmissionScheduleActions.toggleShow('locations'))
            }}
            status={locations.loading ? CardStatus.LOADING : CardStatus.NONE}
          >
            {t('common.locations')}
          </CardHeader>

          <div
            className={
              locations.show ? 'EmissionSchedule__locations' : 'EmissionSchedule__locations--hidden'
            }
          >
            <CreationsFormLocations
              ids={{
                campaignId: urlCampaignId,
                campaignUuid: urlCampaignUuid,
                creationId: urlCreationId,
                creationUuid: urlCreationUuid,
              }}
              mediumFormat={campaignData.mediumFormat}
              readOnly={isMediaReadOnly}
              onChange={() => void setTriggerFetchCreationMedia(true)}
            />
          </div>
        </Card>

        <Card>
          <CardHeader
            number={3}
            collapsed={!emissionNumber.show}
            onCollapse={() => {
              dispatch(campaignEmissionScheduleActions.toggleShow('emissionNumber'))
            }}
            status={emissionNumber.loading ? CardStatus.LOADING : CardStatus.NONE}
          >
            {t('form.campaign.creations.creationEmissions')}
          </CardHeader>
          {emissionNumber.show && campaignData.target === CampaignTarget.IMPRESSIONS && (
            <CreationsFormEmissionNumber
              creationId={selectedCreation.id}
              creations={campaignData.creations}
              campaignEmissions={campaignData.impressions}
            />
          )}

          {emissionNumber.show && campaignData.target === CampaignTarget.AUDIENCE && (
            <CreationsFormAudienceNumber
              creationId={selectedCreation.id}
              creations={campaignData.creations}
              campaignAudience={campaignData.audience}
            />
          )}
        </Card>

        <Card
          headerNumber={4}
          title={t('common.placementOfMedia')}
          isCollapsed={!map.show}
          onCollapse={() => {
            dispatch(campaignEmissionScheduleActions.toggleShow('map'))
          }}
          status={mediaLoading ? CardStatus.LOADING : CardStatus.NONE}
          noMarginBody
        >
          {map.show && (
            <MediaMap
              className='EmissionSchedule__map'
              media={media}
              withClustering
            />
          )}
        </Card>

        <Button
          className='EmissionSchedule__button'
          onClick={() => void navigate(-1)}
        >
          {readOnly ? t('common.back') : t('common.save')}
        </Button>
      </div>
    )
  }

  return campaignData ? Container(campaignData) : <FillingSpinner />
}

export default EmissionSchedule
