import { gql } from '@apollo/client'

export const GET_INVENTORY_FOR_PROGRESS_BAR = gql`
  query inventoryForProgressBar(
    $agglomerations: [String!]
    $buildings: [String!]
    $cities: [String!]
    $mediaFormats: [MediumFormatEnum!]
    $mediumAsIds: [String!]
    $inContacts: Boolean
    $fromDate: ISO8601DateTime
    $toDate: ISO8601DateTime
  ) {
    inventoryForProgressBar(
      agglomerations: $agglomerations
      buildings: $buildings
      cities: $cities
      mediaFormats: $mediaFormats
      mediumAsIds: $mediumAsIds
      inContacts: $inContacts
      fromDate: $fromDate
      toDate: $toDate
    ) {
      lastUpdate
      allocatedSlots
      freeSlots
    }
  }
`
