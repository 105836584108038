import React from 'react'
import TooltipHelp from '../../../../components/TooltipHelp'
import { Trans, useTranslation } from 'react-i18next'

export const CampaignEmissionScheduleTooltip: React.FC = () => {
  const { t } = useTranslation()

  return (
    <TooltipHelp
      className='EmissionSchedule__tooltip'
      containerClassName='EmissionSchedule__tooltip--b'
    >
      <b>{t('emissionSchedule.infoA')}</b>
      <p>
        <Trans i18nKey='emissionSchedule.infoB'>
          <b>
            <>{{ single: t('emissionSchedule.single') }}</>
          </b>
        </Trans>
      </p>
      <p>
        <Trans i18nKey='emissionSchedule.infoC'>
          <b>
            <>{{ timePeriod: t('emissionSchedule.timePeriod') }}</>
          </b>
        </Trans>
      </p>
      <p>{t('emissionSchedule.infoD')}</p>
    </TooltipHelp>
  )
}
