import { Dispatch, SetStateAction } from 'react'
import api from 'api'
import { InventoryProgressBarData } from './types'
import { InventoryChartFilters } from 'pages/Inventory/models/types'

export default class InventoryProgressBarService {
  static getData = (
    setData: Dispatch<SetStateAction<InventoryProgressBarData | null>>,
    setIsLoading: Dispatch<SetStateAction<boolean>>,
    filters: InventoryChartFilters,
    inContacts: boolean
  ): void => {
    setIsLoading(true)
    api.inventory
      .getInventoryForProgressBar(
        {
          ...filters,
          fromDate: filters.fromDate || undefined,
          toDate: filters.toDate || undefined,
        },
        inContacts
      )
      .then(res => void setData(res.data.inventoryForProgressBar))
      .catch(() => void {})
      .finally(() => void setIsLoading(false))
  }
}
